<template>
  <b-sidebar id="guides-info-sidebar" bg-variant="white" no-header right shadow backdrop>
    <template #default="{ hide }">
      <div class="container-wrapper">
        <section class="header">
          <div class="guides-info-title">Filtros</div>
          <div class="actions">
            <Close class="close" @click="hide" />
          </div>
        </section>

        <section class="guides-summary-items">
          <b-form-group>
            <label for="query">Paciente</label>
            <div class="search-container">
              <Search class="icon-search" />
              <b-form-input
                id="patientOrGuideOrProcedure"
                v-model="filters.patientOrGuideOrProcedure"
                debounce="500"
                placeholder="Buscar paciente, número da guia ou item"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <label for="protocol">
              Situações
            </label>
            <multiselect
              id="situation"
              v-model="filters.situation"
              :options="situations"
              class="with-border"
              :show-labels="false"
              placeholder="Situações"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
          </b-form-group>

          <b-form-group>
            <label for="protocol">
              Folha
            </label>
            <b-form-input
              v-model="filters.sheet_number"
              id="sheet_number"
              type="number"
              placeholder="Descrever"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group>
            <label for="batch_number">
              Lote
            </label>
            <b-form-input
              v-model="filters.batch_number"
              id="batch_number"
              type="number"
              placeholder="Descrever"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group>
            <label for="period">
              Período
            </label>
            <div class="multiselect-container">
              <Periods
                @select="handlePeriod"
              />
            </div>
          </b-form-group>

        </section>

        <div class="wrapper-button">
          <b-button block variant="primary" @click="saveChanges">
            Confirmar
          </b-button>

          <b-button
            @click="resetForm"
            variant="outline-primary"
          >
            Redefinir
          </b-button>

        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { SusGuide } from '@/modules/sus/manageGuides/utils/statuses'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'GuidesInfoSidebar',
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    Search: () => import('@/assets/icons/search.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Periods: () => import('@/components/General/Periods'),
  },
  props: {
    batchId: String,
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      summary: {},
      filters: {
        patientOrGuideOrProcedure: null,
        situation: null,
        batch_number: null,
        sheet_number: null,
        period: {
          type: 'create',
          start: null,
          end: null,
        }
      },
      situations: [
        SusGuide.GUIDE_SITUATION_IN_PREPARATION,
        SusGuide.GUIDE_SITUATION_SENT,
        SusGuide.GUIDE_SITUATION_PAYED,
        SusGuide.GUIDE_SITUATION_FINISHED,
      ],
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      }
    }
  },
  methods: {
    handlePeriod(value) {
      this.filters.period.type = 'create';
      this.filters.period.start = value.start;
      this.filters.period.end = value.end;
    },
    async saveChanges() {
      const isLoading = this.$loading.show()

      try {
        this.$emit('change', this.filters);  
        this.$emit('updateFilterApplied');  
        this.$root.$emit('bv::toggle::collapse', 'guides-info-sidebar')      
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        isLoading.hide()
      }
    },
    async resetForm() {
      this.filters = {
        patientOrGuideOrProcedure: null,
        situation: null,
        batch_number: null,
        sheet_number: null,
        period: null
      };

      this.$emit('change', this.filters);
      this.$emit('updateFilterApplied');  
      this.$root.$emit('bv::toggle::collapse', 'guides-info-sidebar')    
    }
  },
  watch: {
    filters: {
      handler(newValue) {
        
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.search-container {
  display: flex;
  flex-direction: row;
  position: relative;

  .icon-search {
    position: absolute;
    width: 24px;
    height: 24px;
    stroke: var(--neutral-500);
    margin: 7px 8px;
  }

  input {
    padding-left: 40px !important;
  }

}

#guides-info-sidebar {
  min-width: 450px;

  .title {
    display: flex;
    flex-direction: row;

    h4 {
      color: var(--dark-blue);
      font-weight: 700;
      font-size: 18px;
      margin-right: 20px;
    }

    h5 {
      color: var(--blue-600);
      font-weight: 600;
      font-size: 18px;

      span {
        color: var(--neutral-500);
      }
    }
  }

  .guides-summary {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5px;
    padding: 20px;
    border-radius: 8px;
    border: 1px dashed var(--neutral-300);
    background: var(--neutral-100);

    .guides-summary-row {
      display: grid;
      grid-template-columns: auto 1fr auto 3fr;
      gap: 10px;
    }

    dt {
      color: var(--neutral-700);
    }

    dd {
      margin: 0;
    }
  }

  .guides-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .guide-container {
      margin: 10px 0;
    }
  }

  .container-wrapper {
    padding: 0 1rem;
  }

  .header {
    display: flex;
    width: 100%;
    padding: 1rem 0;
    justify-content: space-between;
    align-items: center;
  }

  .guides-info-title {
    font-family: 'Red Hat Display';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    color: var(--dark-blue);
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  .optional {
    font-size: 14px;
    color: var(--type-placeholder);
  }

  .close {
    width: 25px;
    fill: var(--neutral-500);
  }

  .wrapper-button {
    width: 100%;
    display: flex;
    margin: 20px 0 !important;
    flex-direction: column;

    button {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }

    .btn-primary {
      color: var(--neutral-000);
    }

    .btn-outline-primary {
      margin-top: 10px;
      color: var(--blue-500);
    }

    .dropdown-item {
      font-weight: 400;
    }
  }
}
</style>